import { useCallback, useEffect, useMemo, useState } from 'react';

type Img = JSX.IntrinsicElements['img'];

export type UseImageProps = Pick<Img, 'onError' | 'onLoad' | 'src'>;

export const useImage = ({ onError, onLoad, src }: UseImageProps) => {
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [status, setStatus] = useState<'pending' | 'failed' | 'succeeded'>(
    'pending',
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onerror = useCallback(
    (event: ErrorEvent) => {
      setStatus('failed');
      onError?.(event.error);
    },
    [onError],
  );

  const onload = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    event => {
      setHeight(event.currentTarget.height);
      setWidth(event.currentTarget.width);
      setStatus('succeeded');
      onLoad?.(event);
    },
    [onLoad],
  );

  const load = useCallback(() => {
    const image = new Image();
    image.addEventListener('load', onload);
    image.addEventListener('error', onerror);
    image.src = src ?? '';
  }, [onerror, onload, src]);

  useEffect(() => setStatus('pending'), [src]);

  useEffect(() => load(), [load, src]);

  return useMemo(
    () => ({
      height,
      source: src,
      status,
      width,
    }),
    [height, src, status, width],
  );
};
