import { forwardRef } from 'react';

import type { Component } from '../../types.js';
import type { BoxProps } from '../box/index.js';
import { Box } from '../box/index.js';

export type GridProps = BoxProps & {
  alignContent?: BoxProps['alignContent'];
  alignItems?: BoxProps['alignItems'];
  columnGap?: BoxProps['columnGap'];
  display?: BoxProps['display'];
  gap?: BoxProps['gap'];
  justifyContent?: BoxProps['justifyContent'];
  justifyItems?: BoxProps['justifyItems'];
  placeContent?: BoxProps['placeContent'];
  placeItems?: BoxProps['placeItems'];
  rowGap?: BoxProps['rowGap'];
  templateAreas?: BoxProps['gridTemplateAreas'];
  templateColumns?: BoxProps['gridTemplateColumns'];
  templateRows?: BoxProps['gridTemplateRows'];
};

export const Grid = forwardRef<HTMLDivElement, GridProps>(function Grid(
  {
    children,
    display = 'grid',
    templateAreas,
    templateColumns,
    templateRows,
    ...props
  },
  ref,
) {
  return (
    <Box
      display={display}
      gridTemplateAreas={templateAreas}
      gridTemplateColumns={templateColumns}
      gridTemplateRows={templateRows}
      ref={ref}
      {...props}
    >
      {children}
    </Box>
  );
}) as Component<'div', GridProps>;
