import { forwardRef } from 'react';

import type { UseImageProps } from '../../hooks/use-image/index.js';
import { useImage } from '../../hooks/use-image/index.js';
import type { Component, CSSProperties } from '../../types.js';
import type { BoxProps } from '../box/index.js';
import { Box } from '../box/index.js';

export type BackgroundProps = BoxProps & {
  aspectRatio?: CSSProperties['aspectRatio'];
  attachment?: BoxProps['backgroundAttachment'];
  color?: BoxProps['backgroundColor'];
  image?: UseImageProps['src'];
  position?: BoxProps['backgroundPosition'];
  repeat?: BoxProps['backgroundRepeat'];
  size?: BoxProps['backgroundSize'];
};

/**
 * @deprecated Use `BackgroundImage` from `@iheartradio/web.accomplice/background-image` instead
 */
export const Background = forwardRef<HTMLDivElement, BackgroundProps>(
  function Background(
    {
      aspectRatio,
      attachment,
      children,
      color = { dark: '$gray-600', light: '$gray-200' },
      image,
      position,
      repeat,
      size = 'cover',
      ...props
    },
    ref,
  ) {
    const { height, source, width } = useImage({ ...props, src: image });

    return (
      <Box
        {...props}
        aspectRatio={aspectRatio ?? (height > 0 ? width / height : undefined)}
        backgroundAttachment={attachment}
        backgroundColor={color}
        backgroundImage={source ? `url(${source})` : undefined}
        backgroundPosition={position}
        backgroundRepeat={repeat}
        backgroundSize={size}
        ref={ref}
        role={source ? 'img' : undefined}
      >
        {children}
      </Box>
    );
  },
) as Component<'div', BackgroundProps>;
