import type { ReactHTML, ReactNode } from 'react';
import { forwardRef } from 'react';
import { isPlainObject, mergeAll } from 'remeda';
import type { Merge } from 'type-fest';

import { useMapMediaProp } from '../../hooks/use-map-media-prop/index.js';
import type { Component, CSSProperties, MediaValue } from '../../types.js';
import type { BoxProps } from '../box/index.js';
import { Box } from '../box/index.js';

export const kinds = {
  h1: {
    fontSize: '$40',
    fontWeight: '$700',
    letterSpacing: '$3',
    lineHeight: '$48',
  },
  h2: {
    fontSize: '$32',
    fontWeight: '$700',
    letterSpacing: '$6',
    lineHeight: '$40',
  },
  h3: {
    fontSize: '$24',
    fontWeight: '$700',
    letterSpacing: '$1',
    lineHeight: '$30',
  },
  h4: {
    fontSize: '$20',
    fontWeight: '$700',
    letterSpacing: '$1',
    lineHeight: '$24',
  },
  h5: {
    fontSize: '$18',
    fontWeight: '$700',
    letterSpacing: '$4',
    lineHeight: '$24',
  },
  'subtitle-1': {
    fontSize: '$17',
    fontWeight: '$600',
    letterSpacing: '$0',
    lineHeight: '$24',
  },
  'subtitle-2': {
    fontSize: '$16',
    fontWeight: '$600',
    letterSpacing: '$0',
    lineHeight: '$24',
  },
  'subtitle-3': {
    fontSize: '$15',
    fontWeight: '$600',
    letterSpacing: '$0',
    lineHeight: '$20',
  },
  'subtitle-4': {
    fontSize: '$14',
    fontWeight: '$600',
    letterSpacing: '$0',
    lineHeight: '$18',
  },
  'subtitle-5': {
    fontSize: '$13',
    fontWeight: '$600',
    letterSpacing: '$0',
    lineHeight: '$16',
  },
  'body-1': {
    fontSize: '$21',
    fontWeight: '$400',
    letterSpacing: '$1',
    lineHeight: '$28',
  },
  'body-2': {
    fontSize: '$18',
    fontWeight: '$400',
    letterSpacing: '$1',
    lineHeight: '$24',
  },
  'body-3': {
    fontSize: '$16',
    fontWeight: '$400',
    letterSpacing: '$1',
    lineHeight: '$24',
  },
  'body-4': {
    fontSize: '$14',
    fontWeight: '$400',
    letterSpacing: '$1',
    lineHeight: '$18',
  },
  'button-1': {
    fontSize: '$16',
    fontWeight: '$600',
    letterSpacing: '$0',
    lineHeight: '$24',
  },
  'button-2': {
    fontSize: '$14',
    fontWeight: '$600',
    letterSpacing: '$0',
    lineHeight: '$16',
  },
  'caption-1': {
    fontSize: '$14',
    fontWeight: '$600',
    letterSpacing: '$0',
    lineHeight: '$18',
  },
  'caption-2': {
    fontSize: '$14',
    fontWeight: '$400',
    letterSpacing: '$0',
    lineHeight: '$18',
  },
  'caption-3': {
    fontSize: '$12',
    fontWeight: '$600',
    letterSpacing: '$0',
    lineHeight: '$16',
  },
  'caption-4': {
    fontSize: '$12',
    fontWeight: '$400',
    lineHeight: '$16',
    letterSpacing: '$4',
  },
  'overline-1': {
    fontSize: '$11',
    fontWeight: '$700',
    letterSpacing: '$5',
    lineHeight: '$16',
    textTransform: 'uppercase',
  },
  'overline-2': {
    fontSize: '$10',
    fontWeight: '$400',
    letterSpacing: '$4',
    lineHeight: '$14',
  },
  'overline-3': {
    fontSize: '$6',
    fontWeight: '$600',
    letterSpacing: '$4',
    lineHeight: '$8',
  },
  'pre-1': {
    fontSize: '$18',
  },
  'pre-2': {
    fontSize: '$16',
  },
  'pre-3': {
    fontSize: '$12',
  },
} as const;

export const kindToElementMapping: Record<keyof typeof kinds, keyof ReactHTML> =
  {
    'body-1': 'p',
    'body-2': 'p',
    'body-3': 'p',
    'body-4': 'p',
    'button-1': 'button',
    'button-2': 'button',
    'caption-1': 'span',
    'caption-2': 'span',
    'caption-3': 'span',
    'caption-4': 'span',
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    'overline-1': 'span',
    'overline-2': 'span',
    'overline-3': 'span',
    'pre-1': 'pre',
    'pre-2': 'pre',
    'pre-3': 'pre',
    'subtitle-1': 'p',
    'subtitle-2': 'p',
    'subtitle-3': 'p',
    'subtitle-4': 'p',
    'subtitle-5': 'p',
  } as const;

type Kind = keyof typeof kinds;

type Element = (typeof kindToElementMapping)[keyof typeof kindToElementMapping];

export type TextProps = Merge<
  BoxProps & { children?: ReactNode; lines?: number | MediaValue<number> },
  {
    as?: Element;
    kind: Kind | MediaValue<Kind & string>;
  }
>;

export const Text = forwardRef<HTMLParagraphElement, TextProps>(function Text(
  {
    as,
    children,
    color = { dark: '$gray-200', light: '$gray-500' },
    css,
    kind,
    lines,
    ...rest
  },
  ref,
) {
  if (!as) {
    if (!kind) {
      throw new Error('You must pass a `kind` prop to the Text component.');
    }

    if (typeof kind !== 'string') {
      throw new TypeError(
        'You must pass an explicit `as` prop when passing an object the `kind` prop ',
      );
    }
  }

  const _kind = useMapMediaProp(kind, value => kinds[value]);

  const _lines = useMapMediaProp(lines, value => ({
    '-webkit-box-orient': 'vertical',
    '-webkit-box-pack': 'end',
    '-webkit-line-clamp': value,
    boxOrient: 'vertical',
    display: '-webkit-box',
    lineClamp: value,
    lineHeight: 'normal',
    overflow: 'hidden',
    wordBreak: 'break-word',
  }));

  return (
    <Box
      {...rest}
      as={as ?? kindToElementMapping[kind as Kind]}
      color={color}
      css={{
        ...(mergeAll([{ margin: 0 }, _kind, _lines, css]) as CSSProperties),
      }}
      data-kind={isPlainObject(kind) ? JSON.stringify(kind) : kind}
      ref={ref}
    >
      {children}
    </Box>
  );
}) as Component<'p', TextProps>;
