import { type ReactNode, forwardRef, memo } from 'react';
import { isNullish } from 'remeda';

import { media } from '../../core/index.js';
import { Box } from '../box/index.js';
import { Flex } from '../flex/index.js';
import { Grid } from '../grid/index.js';
import { Image } from '../image/index.js';
import { Logomark } from '../logos/logomark.js';
import { Text } from '../text/index.js';
import { Player } from './index.js';

export type FullScreenProps = {
  adBreak?: boolean;
  actions: ReactNode;
  children?: ReactNode;
  companion?: ReactNode | null;
  controls: ReactNode;
  header: ReactNode;
  imageSrc: string;
  isFullScreen?: boolean;
  metadata: ReactNode;
  time: ReactNode;
};

export const PlayerFullScreen = memo(
  forwardRef<HTMLDivElement, FullScreenProps>(
    function PlayerFullScreen(props, ref) {
      const {
        adBreak,
        header,
        imageSrc,
        isFullScreen,
        companion,
        children,
        metadata,
        time,
        controls,
        actions,
        ...restProps
      } = props;

      return (
        <Box
          backgroundColor="$brand-black"
          backgroundSize="cover"
          bottom={0}
          display="block"
          height={{
            [`@container ${media.xsmall}`]: '100dvh',
            [`@container ${media.large}`]: 'calc(100dvh - 8.8rem)',
          }}
          left={0}
          opacity={isFullScreen ? 1 : 0}
          pointerEvents={isFullScreen ? 'auto' : 'none'}
          position="absolute"
          right={0}
          top={0}
          transform={
            isFullScreen ?
              {
                [`@container ${media.xsmall}`]:
                  'translateY(calc((100dvh - 12.4rem) * -1))',
                [`@container ${media.large}`]:
                  'translateY(calc((100dvh - 8.8rem) * -1))',
              }
            : 'translateY(100%)'
          }
          transition="opacity 300ms, visibility 300ms, transform 0ms"
          visibility={isFullScreen ? 'visible' : 'hidden'}
          width="100%"
          zIndex={{
            [`@container ${media.xsmall}`]: '$10',
            [`@container ${media.large}`]: '$1',
          }}
        >
          <Box
            backgroundImage={`linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url('${imageSrc}')`}
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            data-player-type="fullscreen"
            filter="blur(5rem) saturate(1.5)"
            height="87%"
            overflow="hidden"
            position="absolute"
            width="100%"
            zIndex="$1"
          />
          <Box
            data-player-type="fullscreen"
            height="100%"
            overflow="hidden"
            position="relative"
            width="100%"
            zIndex="$10"
          >
            <Box
              height="100%"
              overflow={adBreak ? 'hidden' : 'hidden auto'}
              padding={{
                [`@container ${media.xsmall}`]:
                  children ? '$16 $0 8rem $0' : '$16 $0',
                [`@container ${media.large}`]:
                  children ? '$36 $0 8rem $0' : '$36 $0',
              }}
            >
              <Grid
                height={{
                  [`@container ${media.xsmall}`]:
                    children ? 'min-content' : '100%',
                  [`@container ${media.small}`]: children ? '87%' : '100%',
                }}
                minHeight={{
                  [`@container ${media.xsmall}`]: '60rem',
                  [`@container ${media.large}`]: '40rem',
                }}
                padding={{
                  [`@container ${media.xsmall}`]: '$0 $16 $32 $16',
                  [`@container ${media.large}`]: '$0 $32 $0 $32',
                }}
                ref={ref}
                templateAreas={`
              "header"
              "artwork"
              "metadata"
              "time"
              "controls"
              "actions"
            `}
                templateRows={{
                  [`@container ${media.xsmall}`]: 'repeat(6, min-content)',
                  [`@container ${media.small}`]:
                    'min-content 1fr min-content min-content',
                  [`@container ${media.large}`]: 'min-content 1fr min-content',
                }}
                width="100%"
                {...restProps}
              >
                <Flex
                  alignItems="center"
                  alignSelf="center"
                  gridArea="header"
                  justifyContent="space-between"
                  placeSelf="start"
                  width="100%"
                >
                  <Text
                    as="p"
                    color="$brand-white"
                    kind={{
                      [`@container ${media.xsmall}`]: 'subtitle-5',
                      [`@container ${media.medium}`]: 'subtitle-2',
                      [`@container ${media.large}`]: 'subtitle-1',
                    }}
                    lines={1}
                    width={{ '@initial': '80%', '@medium': '60%' }}
                    zIndex="$10"
                  >
                    {header}
                  </Text>
                  <Player.Expand
                    display={{
                      [`@container ${media.xsmall}`]: 'block',
                      [`@container ${media.large}`]: 'none',
                    }}
                    padding="$0"
                  />
                </Flex>
                <Flex
                  alignItems="center"
                  data-name="fullscreen-artwork"
                  display={adBreak && isNullish(companion) ? 'none' : 'flex'}
                  gridArea="artwork"
                  height="100%"
                  justifyContent="center"
                  objectFit="contain"
                  overflow="hidden"
                  padding={{
                    [`@container ${media.xsmall}`]: '$32',
                    [`@container ${media.large}`]: '$40',
                  }}
                  width="100%"
                >
                  {!isNullish(companion) && isFullScreen ?
                    companion
                  : <Image
                      alt="artwork"
                      aspectRatio="1 / 1"
                      borderRadius="$6"
                      display="flex"
                      fallback={
                        <Logomark
                          fill="gray-300"
                          size={{ '@initial': 124, '@large': 222 }}
                        />
                      }
                      height={{ '@initial': 272, '@large': 736 }}
                      maxHeight={{
                        [`@container ${media.xsmall}`]: '100%',
                        [`@container ${media.small}`]:
                          children ? '100%' : '80%',
                        [`@container ${media.large}`]:
                          children ? '100%' : '60%',
                      }}
                      maxWidth={{
                        [`@container ${media.xsmall}`]: '100%',
                        [`@container ${media.small}`]:
                          children ? '100%' : '80%',
                        [`@container ${media.large}`]:
                          children ? '100%' : '60%',
                      }}
                      objectFit="contain"
                      src={imageSrc}
                      width="auto"
                    />
                  }
                </Flex>
                {metadata}
                {time}
                {controls}
                {actions}
              </Grid>
              {children}
            </Box>
            {children ?
              <Box
                background="linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(1, 1, 1, 0.8) 100%);"
                bottom="-1px"
                height="25rem"
                pointerEvents="none"
                position="sticky"
                width="100%"
                zIndex="$10"
              />
            : null}
          </Box>
        </Box>
      );
    },
  ),
);

PlayerFullScreen.displayName = 'Player.FullScreen';
