import type { ComponentProps } from '@stitches/react';
import { type ReactNode, forwardRef } from 'react';

import { styled } from '../../stitches.config.js';
import type { Component, CSSProperties, CSSProperty } from '../../types.js';
import { type PropertyKey, useFormatCss } from './use-format-css.js';
import { useStyleProps } from './use-style-props.js';

const Primitive = styled('div');

type PrimitiveProps = ComponentProps<typeof Primitive>;

export type BoxProps = {
  children?: ReactNode;
  css?: CSSProperties;
  onClick?: PrimitiveProps['onClick'];
} & {
  [Key in PropertyKey]?: CSSProperty<Key>;
} & Partial<HTMLOrSVGElement>;

export const Box = forwardRef<HTMLDivElement, BoxProps>(function Box(
  { children, css, ...props }: BoxProps,
  ref,
) {
  const { cssProperties: cssShorthandProps, htmlAttributes } =
    useStyleProps(props);
  const mergedCss = useFormatCss(cssShorthandProps, css);

  return (
    <Primitive css={mergedCss} ref={ref} {...htmlAttributes}>
      {children}
    </Primitive>
  );
}) as Component<'div', BoxProps>;
