import { forwardRef } from 'react';

import type { Component } from '../../types.js';
import type { BoxProps } from '../box/index.js';
import { Box } from '../box/index.js';

export type SeparatorProps = BoxProps & {
  orientation?: 'horizontal' | 'vertical';
};

export const Separator = forwardRef<HTMLDivElement, SeparatorProps>(
  function Separator(
    {
      backgroundColor = { dark: '$gray-500', light: '$gray-150' },
      orientation = 'horizontal',
      ...props
    },
    ref,
  ) {
    return (
      <Box
        {...props}
        backgroundColor={backgroundColor}
        data-orientation={orientation}
        height={orientation === 'horizontal' ? '0.1rem' : '100%'}
        ref={ref}
        role="separator"
        width={orientation === 'horizontal' ? '100%' : '0.1rem'}
      />
    );
  },
) as Component<'div', SeparatorProps>;
