import { Menu as _Menu } from './menu.js';
import { LeftSlot, MenuItem, RightSlot } from './menu-item.js';
import { Sub } from './menu-sub.js';

export const Menu = Object.assign(_Menu, {
  Item: Object.assign(MenuItem, { LeftSlot, RightSlot }),
  Sub,
});

export type { MenuProps } from './menu.js';
export type { MenuItemProps } from './menu-item.js';
export type { MenuSubProps } from './menu-sub.js';
