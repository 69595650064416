import { useUncontrolled } from '@mantine/hooks';
import { forwardRef } from 'react';

import type { UseUncontrolledInput } from '../../types.js';
import { Field } from '../field/index.js';
import { Flex } from '../flex/index.js';
import {
  type BaseInputProps,
  type InputPropsWithFieldProps,
  useInputProps,
} from '../input/index.js';
import { RadioGroup as Primitive } from './radio-button.primitive.js';

export type RadioGroupProps = InputPropsWithFieldProps<
  BaseInputProps,
  UseUncontrolledInput<string> & {
    orientation?: 'vertical' | 'horizontal';
  }
>;

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  function RadioGroup(props: RadioGroupProps, ref) {
    const { fieldProps, inputProps, orientation, children, ...restProps } =
      useInputProps(props, {
        orientation: 'horizontal',
      });
    const { onChange, value, defaultValue, name, required } = inputProps;

    const [_value, handleChange] = useUncontrolled<string>({
      onChange,
      defaultValue,
      value,
    });

    return (
      <Primitive
        {...restProps}
        name={name}
        onValueChange={handleChange}
        orientation={orientation}
        ref={ref}
        required={required}
        value={_value}
      >
        <Field {...fieldProps} inlineLabel={false}>
          <Flex
            alignItems="flex-start"
            aria-orientation={orientation}
            css={{
              '&[aria-orientation="horizontal"]': {
                flexDirection: 'row',
              },
              '&[aria-orientation="vertical"]': {
                flexDirection: 'column',
              },
            }}
            data-test="radio-buttons-wrapper"
            flexWrap="wrap"
            gap="$16"
          >
            {children}
          </Flex>
        </Field>
      </Primitive>
    );
  },
);
