import { useId } from 'react';
import type { Simplify } from 'type-fest';

import type { SimpleMerge } from '../../types.js';
import type { FieldProps } from '../field/index.js';

/**
 * This type is useful for input-like prop types where the component rendered is not an actual <input />
 * (which is common for Radix UI components)
 */
export type BaseInputProps<V extends string | number | boolean = any> =
  Simplify<
    FieldProps & {
      id?: string;
      name: string;
      'aria-label'?: string;
      required?: boolean;
      placeholder?: string;

      defaultValue?: V;
      onChange?: (value: V) => void;
      value?: V;
    } & Record<string, any>
  >;

export type InputPropsWithFieldProps<
  ComponentProps = Record<string, unknown>,
  Extra = Record<string, unknown>,
> = Simplify<
  SimpleMerge<
    SimpleMerge<ComponentProps, Omit<FieldProps, 'css'>>,
    SimpleMerge<{ name: string }, Extra>
  >
>;

/**
 * This hook is useful for components using the base `Input` component with the `Field` component.
 *
 * Components using both `Input` and `Field` will receive props for both of these subcomponents.
 * This hook separates props for `Field` and props for `Input` out of the greater `props` object,
 * as well as handling fallbacks (for things like matching `id`s for both `label` and `input`) in
 * the case they aren't provided.
 *
 * @param props
 * @param defaultProps
 * @returns
 */
export function useInputProps<T extends BaseInputProps = BaseInputProps>(
  props: T,
  defaultProps: Partial<T> = {},
) {
  const mergedProps = { ...defaultProps, ...props };

  const {
    defaultValue,
    disabled,
    message,
    hint,
    id,
    inline,
    inlineLabel,
    isError,
    label,
    name,
    onChange,
    placeholder,
    required,
    value,
    ...restMergedProps
  } = mergedProps;

  const uid = useId();

  return {
    ...restMergedProps,

    fieldProps: {
      disabled,
      message,
      hint,
      id: id ?? uid,
      inline,
      inlineLabel,
      label,
      required,
    },

    inputProps: {
      'aria-label': mergedProps['aria-label'] ?? label ?? placeholder,
      'aria-disabled': disabled ?? mergedProps['aria-disabled'],
      defaultValue,
      disabled,
      id: id ?? uid,
      isError,
      name,
      onChange,
      placeholder,
      required,
      value,
    },
  };
}
