import { type ComponentProps, forwardRef } from 'react';
import type { SetRequired } from 'type-fest';

import { InputPrimitive } from './input.primitive.js';

export interface InputSharedProps {
  /** The input `name` attribute */
  name: string;
}

export type InputProps = SetRequired<
  Omit<ComponentProps<typeof InputPrimitive>, 'inlist'>,
  'name'
>;

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  props: InputProps,
  ref,
) {
  const {
    id,
    kind = 'flushed',
    name,
    placeholder,
    required,
    type = 'text',
    ...restProps
  } = props;

  return (
    <InputPrimitive
      data-test={`${type}-input`}
      id={id}
      kind={kind}
      name={name}
      placeholder={placeholder}
      ref={ref}
      required={required}
      type={type}
      {...restProps}
    />
  );
});

Input.toString = () => '.input';
Input.displayName = 'Input';
