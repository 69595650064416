import type { RefObject } from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';

import type { CSSProperties, VariantProps } from '../../types.js';
import { Text } from '../text/index.js';
import { Tooltip } from '../tooltip/index.js';
import { Range, Root, Thumb, Track } from './slider.primitive.js';

export type SliderProps = Omit<VariantProps<typeof Root>, 'active'> & {
  max?: number;
  min?: number;
  onChange?: (value: number) => void;
  step?: number;
  tooltip?: string;
  value: number;
  css?: CSSProperties;
  trackCss?: CSSProperties; // Only implemented to be used for Podcast Episode Row UI - should not be used otherwise - Will be refactored in the future
  rangeCss?: CSSProperties; // Only implemented to be used for Podcast Episode Row UI - should not be used otherwise - Will be refactored in the future
};

export const Slider = forwardRef<HTMLSpanElement, SliderProps>(function Slider(
  {
    disabled = false,
    interactive = true,
    max = 100,
    min = 0,
    onChange,
    step = 1,
    tooltip = 'Seek',
    value,
    css,
    trackCss,
    rangeCss,
    ...props
  },
  ref,
) {
  const sliderRef = useRef<HTMLSpanElement>(null);
  const thumbRef = useRef<HTMLSpanElement>(null);

  const [pressed, setPressed] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (pressed) return;
    setInternalValue(value);
  }, [pressed, value]);

  useEffect(() => {
    const { current: slider } = (ref ??
      sliderRef) as RefObject<HTMLSpanElement>;
    const { current: thumb } = thumbRef;

    if (!slider || !thumb) return;

    function handleMouseMove(event: MouseEvent) {
      slider?.style.setProperty('--x', `${event.offsetX}px`);
    }

    function handleMouseDown() {
      setPressed(true);
    }

    function handleMouseUp(event: MouseEvent) {
      if (!slider || !thumb) return;

      const offset =
        thumb === event.target ?
          Number(thumb.getAttribute('aria-valuenow'))
        : (event.offsetX / slider.offsetWidth) * max;

      setInternalValue(offset);
      onChange?.(offset);
      setTimeout(() => setPressed(false), 250);
    }

    slider.addEventListener('mousemove', handleMouseMove);
    slider.addEventListener('pointerdown', handleMouseDown);
    slider.addEventListener('pointerup', handleMouseUp);

    return function cleanup() {
      slider.removeEventListener('mousemove', handleMouseMove);
      slider.removeEventListener('pointerdown', handleMouseDown);
      slider.removeEventListener('pointerup', handleMouseUp);
    };
  }, [max, onChange, ref]);

  return (
    <Root
      {...props}
      css={css}
      disabled={disabled}
      interactive={interactive}
      max={max}
      min={min}
      onValueChange={([x]: number[]) => {
        if (pressed) return;
        onChange?.(x);
      }}
      pressed={pressed}
      ref={ref ?? sliderRef}
      step={step}
      value={pressed ? undefined : [internalValue]}
    >
      <Track css={trackCss}>
        <Range css={rangeCss} />
      </Track>
      <Tooltip side="top" trigger={<Thumb ref={thumbRef} />}>
        <Text
          color={{
            dark: '$brand-black',
            light: '$brand-white',
          }}
          kind="caption-3"
        >
          {tooltip}
        </Text>
      </Tooltip>
    </Root>
  );
});
